import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import hawkeye from "../images/hawkeye-logo.png"

const AdminAndMaintenance = () => (
  <Layout>
    <SEO title="Admin and Maintenance" />
    <div className="page-content">
      <div className="container">
        <h1 className="text-orange">Admin & Maintenance</h1>
        <div className="row">
          <div className="col-md-8">
            <p>
              Gray Hawk supports the management of business land records through
              a comprehensive list of administrative and maintenance services.
              We also have a diligent and reliable team of lease analysts, GIS
              mapping specialists, and order analysts to keep you on track and
              prepared.
            </p>
            <p>
              Whether you need to create land database systems, track special
              provisions, or manage specifically tricky situations - our capable
              team will take a good look at your project needs and provide
              solutions that bear quantifiable and satisfying results.
            </p>
            <p>
              We deploy advanced artificial intelligence technology as well as
              front-end and back-end support to meet your needs.
            </p>
          </div>
          <div className="col-lg-4">
            <img src={hawkeye} className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="my-3">Administrative and Maintenance Services:</h2>
            {/* <p>
              To know more about our data extraction and artificial intelligence
              solutions, send us an email at
              <a href="mailto:info@grayhawkland.com" className="mx-1">
                info@grayhawkland.com
              </a>
              or reach us via this number:
              <a href="tel:303.223.1665" className="mx-1">
                303.223.1665
              </a>
            </p> */}
          </div>
          <div className="col-lg-8 ml-lg-4">
            <ul className="my-3">
              <li>Acquisition and Divestiture Lease Data integration</li>
              <li>
                Analyze and comply with Title Opinion curative requirements
              </li>
              <li>
                Calculate Gross Working Interest (GWI), Net Revenue Interest
                (NRI), Overriding Royalty Interests (ORRI) on a Lease and depth
                basis
              </li>
              <li>Consult on choosing a Land System that fits your needs.</li>
              <li>Correspondence handling for maintenance of lease records</li>
              <li>
                Digitize Land Records to make them available for secure Online
                Cloud viewing, printing, or sharing from any computer or mobile
                device.
              </li>
              <li>
                Extensive reporting covering Acreage Reports, Payment
                Obligations, Special Obligations, Lease Schedules and other Land
                Management Reports available by Tract or Lease basis
              </li>
              <li>
                File Maintenance of complete lease, tract, contract, and well
                files
              </li>
              <li>
                Input Leases, Contracts and Wells into a fully integrated
                Tract-Based Land Management System, or into any Land system of
                your choice.
              </li>
              <li>
                Liaise and manage communications with lease brokers, attorneys,
                abstractors, and landowners as needed
              </li>
              <li>
                Make and track payments such as: Bonus, Delay Rental, and
                Shut-In Royalty Payments
              </li>
              <li>Manage Mineral Tax Accounting and Compliance</li>
              <li>Notice or Mail-out Drafting, Mailout and Tracking</li>
              <li>Obtain curative documentation to clear cloud-of-title</li>
              <li>Obtain recorded documents and file instruments for record</li>
              <li>Owner Relations Management</li>
              <li>
                Paper or Digital File Setup of lease, tract, contract, and well
                files
              </li>
              <li>
                Research and determine Mineral Acreage and Company Net Acreage
              </li>
              <li>
                Review and analyze Leases for vital clauses and obligations
              </li>
              <li>
                Scan Land Files for Land System integration, Archiving of paper
                files, Due Diligence or Acquisition/Divestiture File Room
                creation
              </li>
              <li>
                Secure Data Room creation and hosting with customizable
                password-protected access
              </li>
            </ul>
          </div>
          <div className="col-lg-12">
            <p>
              Gray Hawk Land Solutions is all about providing our clients with
              peace of mind when it comes to land administration and management.
              Our comprehensive solutions can meet a wide range of difficult
              situations that your business may encounter.
            </p>
            <p>
              Go through surface contracts and easements and right-of-way
              agreements to find the needed data to make intelligent business
              decisions. You can even extract calls to GIS Maps and process AFE
              and JIB Extractions.{" "}
            </p>
            <p>
              Track your assets with ease and improve your operations from top
              to bottom with the use of our artificial intelligence technology
              and other land solutions.
            </p>
          </div>
          <div className="text-center w-100 mt-5">
            <Link className="submit-btn" to="/#contact">
              contact us
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default AdminAndMaintenance
